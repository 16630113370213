<template>
  <div class="flex items-center mb-16 w-full">
    <OrganizationCampaigns />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrganizationDashboard",

  metaInfo: {
    title: "Dashboard",
  },

  components: {
    OrganizationCampaigns: () => import("@/views/organization/OrganizationCampaigns"),
  },

  computed: {
    ...mapGetters(["getOrganization", "getCampaigns", "getUserInfo"]),

    profileImage() {
      if (this.getUserInfo?.info?.profileImage) {
        return this.getUserInfo?.info?.profileImage;
      }

      if (this.getOrganization?.logo) {
        return this.getOrganization?.logo;
      }

      return null;
    },
  },
};
</script>
